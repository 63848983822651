import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import {
  GaService,
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
  GoogleAnalyticsTrackingId,
} from '@shared-lib/google-analytics';
import {
  OperatorMonitorEffects,
  operatorMonitorReducers,
} from './shared/store';

import { APPLICATION_INSIGHTS_KEY } from '@shared-lib/app-insights';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
//paldesk
import { BASE_PATH as BASE_PATH_FLEETSERVICE } from '@connected/data-access/fleet-service';
import { BASE_PATH as BASE_PATH_IDENTITY_SERVICE } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { BASE_PATH as BASE_PATH_MAINTENANCE } from '@paldesk/shared-lib/data-access/maintenance-service-generated';
import { BASE_PATH as BASE_PATH_PALCHART } from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { BASE_PATH as BASE_PATH_PALCODE } from '@paldesk/shared-lib/data-access/palcode-service-generated';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
//angular
import { CommonModule } from '@angular/common';
//third party
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { InstallPromptComponent } from './install-prompt/install-prompt.component';
import { IsPdIconPipe } from '@shared-lib/is-pd-icon-pipe';
import { LanguageSelectionModule } from '@shared-lib/language-selection';
import { MapAlertCodePipe } from './alerts/alerts-finder/alert-to-code-pipe/alert-to-code-pipe.pipe';
import { NewVersionInfoComponent } from './new-version-info/new-version-info.component';
import { NgModule } from '@angular/core';
import { NoEquipmentInfoComponent } from './no-equipment-info/no-equipment-info.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { SharedModule } from './shared/shared.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MobileSetupService } from './shared/services/mobile-setup.service';
import { Capacitor } from '@capacitor/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { HeaderComponent } from './header/header.component';
import {
  SignalRService,
  BASE_PATH as SIGNAL_R_BASEPATH,
} from '@connected/data-access/fleet-service/signal-r';
import { LandingComponent } from './landing/landing.component';
import { DsSnackbarService } from '@design-system/feature/snackbar';

const isMobileApp = Capacitor.isNativePlatform();
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    IsPdIconPipe,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    LanguageSelectionModule,
    SharedLibFeatTranslationModule,
    // analytics

    // http
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot({
      isMobileApp,
    }),

    // app
    AppRoutingModule,
    OperatorMonitorEffects,
    StoreModule.forRoot(operatorMonitorReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          maxAge: 50,
          connectInZone: true,
        }),
    EffectsModule.forRoot([]),
    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.google_analytics_id || '',
    }),
    InstallPromptComponent,
    HeaderComponent,
  ],
  declarations: [
    AppComponent,
    SidebarComponent,
    NewVersionInfoComponent,
    NoEquipmentInfoComponent,
    LandingComponent,
  ],
  providers: [
    // auth
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () =>
        isMobileApp
          ? `${environment.oidc_clientid}.Native`
          : environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () =>
        isMobileApp
          ? `${environment.oidc_scope} offline_access`
          : environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () =>
        isMobileApp
          ? environment.LINKS.POST_LOGOUT_REDIRECT_URI
          : environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // http interceptor
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // analytics
    GaService,
    {
      provide: GoogleAnalyticsTrackingId,
      useFactory: () => environment.google_analytics_id,
    },
    {
      provide: APPLICATION_INSIGHTS_KEY,
      useFactory: () => environment.application_insights_key,
    },
    // services
    {
      provide: BASE_PATH_IDENTITY_SERVICE,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: BASE_PATH_MAINTENANCE,
      useFactory: () =>
        environment.palfinger_app_gateway_connected + '/conn-mcl',
    },
    {
      provide: BASE_PATH_FLEETSERVICE,
      useFactory: () => environment.palfinger_api_management + '/fleet-control',
    },
    {
      provide: BASE_PATH_PALCODE,
      useFactory: () => environment.palfinger_app_gateway + '/palcode',
    },
    {
      provide: BASE_PATH_PALCHART,
      useFactory: () =>
        environment.palfinger_app_gateway_connected + '/conn-palchart',
    },
    {
      provide: SIGNAL_R_BASEPATH,
      useFactory: () =>
        environment.palfinger_app_gateway_connected + '/conn-signalr',
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    MapAlertCodePipe,
    MobileSetupService,
    InAppBrowser,
    SignalRService,
    DsSnackbarService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
